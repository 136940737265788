import React from "react"
import PropTypes from "prop-types"

const Html = ({ html, className }) => {
  return (
    <div className={`markdown ${className}`} dangerouslySetInnerHTML={{ __html: html }} />
  )
}

Html.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
}

Html.defaultProps = {
  html: '',
  className: ''
}

export default Html