import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageSection from "../components/page-section"

const IndexPage = ({ data }) => {
  const { sections } = data.allContentfulPage.edges[0].node;

  return (
    <Layout>
      <Seo title="Home" />
      {sections.map(({ id, ...props }) => <PageSection key={id} {...props} />)}
    </Layout>
  )
}

export default IndexPage

export const query = graphql `
  query IndexQuery {
    allContentfulPage {
      edges {
        node {
          sections {
            id
            title
            background
            showTitle
            content {
              __typename
              ... on ContentfulFeatureSection {
                featureList
                description {
                  childMarkdownRemark{
                    html
                  }
                }
                image {
                  fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid
                  }
                }
              }
              ... on ContentfulTestimonialsSection {
                testimonials {
                  id
                  cite
                  quote {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
              ... on ContentfulProcessSection {
                processItems {
                  id
                  icon {
                    fluid(maxWidth: 300) {
                      ...GatsbyContentfulFluid
                    }
                  }
                  title
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
              ... on ContentfulMarkdownSection {
                id
                content {
                  childMarkdownRemark {
                    html
                  }
                }
                callsToAction {
                  id
                  text
                  url
                  action {
                    event
                    arguments
                  }
                  background
                }
              }
              ... on ContentfulLeadSection {
                id
                introduction {
                  childMarkdownRemark {
                    html
                  }
                }
                logo {
                  file {
                    url
                  }
                }
                callsToAction {
                  id
                  text
                  url
                  action {
                    event
                    arguments
                  }
                  background
                }
              }
            }
          }
        }
      }
    }
  }
`
