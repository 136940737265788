import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import CallsToAction from "./calls-to-action"
import Html from "./html"

const MarkdownSection = ({ content, callsToAction }) => {
  return (
    <Fragment>
      <Html className="mb-4" html={content.childMarkdownRemark.html} />
      <Row className="justify-content-center">
        <Col md={6}>
          <CallsToAction callsToAction={callsToAction} />
        </Col>
      </Row>
    </Fragment>
  )
}

MarkdownSection.propTypes = {
  content: PropTypes.any,
  callsToAction: PropTypes.any
}

MarkdownSection.defaultProps = {
  content: { childMarkdownRemark: { html: '' } },
  callsToAction: []
}

export default MarkdownSection

