import React from "react"
import PropTypes from "prop-types"
import { Carousel } from "react-bootstrap"
import Html from "./html"

const TestimonialsSection = ({ testimonials }) => {
  return (
    <Carousel className="py-3">
      {testimonials.map(({ id, cite, quote }) => {
        return (
          <Carousel.Item key={id}>
            <blockquote className="blockquote m-0">
              <Html html={quote.childMarkdownRemark.html} />
              <cite className="text-muted">- {cite}</cite>
            </blockquote>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

TestimonialsSection.propTypes = {
  testimonials: PropTypes.array
}

TestimonialsSection.defaultProps = {
  testimonials: []
}

export default TestimonialsSection

