import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import CallsToAction from "./calls-to-action"
import Html from "./html"
// import signupForm from "../data/signup-form"

const LeadSection = ({ introduction, callsToAction }) => {
  return (
    <Fragment>
      <Row className="mb-5 justify-content-center">
        <Col md={{ span: 3 }} className="text-center">
          <object type="image/svg+xml" data={"/images/digital-desk-text.svg"}>Digital Desk</object>
          {/* only SVGs in object tags can import custom fonts, can't be imported by webpack or gatsby font / source plugins */}
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={{ span: 10, offset: 1 }}>
          <Html className="markdown--lead" html={introduction.childMarkdownRemark.html} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <CallsToAction callsToAction={callsToAction} />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Html html={signupForm} />
        </Col>
      </Row> */}
    </Fragment>
  )
}

LeadSection.propTypes = {
  logo: PropTypes.any,
  introduction: PropTypes.any,
  callsToAction: PropTypes.array
}

LeadSection.defaultProps = {
  introduction: { childMarkdownRemark: { html: '' } },
  callsToAction: []
}

export default LeadSection

