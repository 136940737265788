import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
import FeatureSection from "./feature-section"
import TestimonialsSection from "./testimonials-section"
import ProcessSection from "./process-section"
import LeadSection from "./lead-section"
import MarkdownSection from "./markdown-section"
import classnames from "classnames"

const PageSection = ({ title, showTitle, background, content }) => {

  const getSectionContent = () => {
    switch (content.__typename) {
      case "ContentfulLeadSection": {
        return <LeadSection {...content} />
      }
      case "ContentfulFeatureSection": {
        return <FeatureSection {...content} background={background} />
      }
      case "ContentfulTestimonialsSection": {
        return <TestimonialsSection {...content} />
      }
      case "ContentfulProcessSection": {
        return <ProcessSection {...content} />
      }
      case "ContentfulMarkdownSection": {
        return <MarkdownSection {...content} />
      }
      default: {
        return null
      }
    }
  }

  const className = classnames('py-5', {
    'bg-primary text-white shadow--inset': background === 'Orange',
    'bg-info text-white shadow--inset': background === 'Blue',
    'bg-white text-dark': background === 'White',
  })

  return (
    <section className={className}>
      <Container>
        {showTitle && <h2 className="h2 mb-4">{title}</h2>}
        {getSectionContent()}
      </Container>
    </section>
  )
}

PageSection.propTypes = {
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  background: PropTypes.string,
  content: PropTypes.any.isRequired
}

PageSection.defaultProps = {
  title: '',
  showTitle: true,
  background: 'White',
}

export default PageSection

