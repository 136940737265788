import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card } from "react-bootstrap"
import Img from "gatsby-image"
import classnames from "classnames"
import Html from "./html"

const FeatureSection = ({ description, featureList, background, image }) => {
  const iconClassName = classnames('fas fa-check mr-3', {
    'text-info': background === 'Orange',
    'text-primary': background !== 'Orange',
  })

  const cardBg = classnames({
    'info': background === 'White',
    'white': background !== 'White',
  })

  const cardText = classnames({
    'light': background === 'White',
    'info': background !== 'White',
  })

  return (
    <Fragment>
      <Row className="align-items-center">
        <Col md={6} className="mb-4 mb-md-0">
          <ul className="h3 list-unstyled font-weight-light">
            {featureList.map((feature, index) => {
              const className = classnames('d-flex align-items-center', {
                'mb-3': index < featureList.length-1
              })
              return <li data-aos="fade-right" data-aos-delay={index*250} key={index} className={className}><span className={iconClassName}></span>{feature}</li>
            })}
          </ul>
        </Col>
        <Col md={6}>
          <Card text={cardText} bg={cardBg} data-aos="zoom-in">
            <Card.Body>
              <Html html={description.childMarkdownRemark.html} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {image && <Row className="pt-5" style={{ marginBottom: '-3rem' }}>
        <Col md={{ span: 4, offset: 4 }}>
          <Img fluid={image.fluid} />
        </Col>
      </Row>}
    </Fragment>
  )
}

FeatureSection.propTypes = {
  description: PropTypes.any,
  featureList: PropTypes.arrayOf(PropTypes.string),
  background: PropTypes.oneOf(['Orange', 'White', 'Blue']),
  image: PropTypes.any
}

FeatureSection.defaultProps = {
  description: { childMarkdownRemark: { html: '' } },
  featureList: [],
  background: 'White'
}

export default FeatureSection

