import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import Html from "./html"

const ProcessSection = ({ processItems }) => {
  const stepOne = processItems[0];
  const stepTwo = processItems[1];
  const stepThree = processItems[2];

  return (
    <Fragment>
      <Row className="align-items-end mb-4">
        <Col md={4} className="d-none d-md-block text-right">
          <span className="text-info fas fa-arrow-up fa-2x tilt-right"></span>
        </Col>
        <Col md={4} className="text-center pb-md-5">
          <Img className="mb-3 w-25 mx-auto" fluid={stepOne.icon.fluid} />
          <h4>{stepOne.title}</h4>
          <Html html={stepOne.description.childMarkdownRemark.html } />
        </Col>
        <Col md={4} className="d-none d-md-block text-left">
          <span className="text-info fas fa-arrow-down fa-2x tilt-left"></span>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={{ span: 4, order: 3 }} className="text-center mb-4 mb-md-0">
          <Img className="mb-3 w-25 mx-auto" fluid={stepTwo.icon.fluid} />
          <h4>{stepTwo.title}</h4>
          <Html html={stepTwo.description.childMarkdownRemark.html } />
        </Col>
        <Col md={{ span: 2, order: 2 }} className="d-none d-md-block text-center">
          <span className="text-info fas fa-arrow-left fa-2x"></span>
        </Col>
        <Col md={{ span: 4, order: 1, offset: 1 }} className="text-center">
          <Img className="mb-3 w-25 mx-auto" fluid={stepThree.icon.fluid} />
          <h4>{stepThree.title}</h4>
          <Html html={stepThree.description.childMarkdownRemark.html } />
        </Col>
      </Row>
    </Fragment>
  )
}

ProcessSection.propTypes = {
  processItems: PropTypes.array
}

ProcessSection.defaultProps = {
  processItems: []
}

export default ProcessSection

