import React from "react"
import PropTypes from "prop-types"
import { Button, ButtonGroup } from "react-bootstrap"
import classnames from "classnames"

const CallsToAction = ({ callsToAction }) => {
  const handleClick = (action) => {
    if(!action) return

    switch (action.event) {

      case "SIGN_UP": {
        console.log(action)
        return
      }
      case "TRY_DEMO": {
        window.open("https://demo.digital-desk.co.uk");
        return
      }
      default:
        return
    }
  }

  return (
    <ButtonGroup size="lg" className="w-100">
      { !!callsToAction && callsToAction.map(({ id, text, background, url, action }) => {
        const variant = classnames({
          'primary': background === 'Orange',
          'light': background === 'White',
          'info': background === 'Blue',
        })
        return (
          <Button key={id} onClick={() => handleClick(action)} style={{ width: `${(100 / callsToAction.length)}%` }} variant={variant} to={url}>{text}</Button>
        )
      })}
    </ButtonGroup>
  )
}

CallsToAction.propTypes = {
  callsToAction: PropTypes.any,
}

CallsToAction.defaultProps = {
  callsToAction: []
}

export default CallsToAction